@import 'variables';

.modal {
	&__backdrop {
		background: rgba(244, 244, 246, 0.8);
		backdrop-filter: blur(2px);
		position: fixed;
		width: 100vw;
		height: 100vh;
		top: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: z-index(modalBackdrop);

		&[hidden] {
			display: none;
		}
	}

	&__wrapper {
		z-index: z-index(modal);
		border-radius: 24px;
		background: $color-white;
		@include shadow-24();
		padding: rem(24);
		display: flex;
		gap: rem(32);
		flex-direction: column;
		width: calc(100% - 32px);

		@include device((tablet-up)) {
			width: 700px;
		}

		&--small {
			gap: 0;

			@include device((tablet-up)) {
				width: 500px;
			}

			.modal {
				&__content {
					gap: rem(20);
				}

				&__buttons {
					text-align: center;
				}
			}
		}
	}

	&__close-icon {
		cursor: pointer;
		text-align: right;
	}

	&__content {
		display: flex;
		gap: rem(24);
		flex-direction: column;

		@include device((tablet-up)) {
			margin: rem(0 24);
		}
	}

	&__description {
		max-height: 75vh;
		overflow: auto;

		[hidden] {
			display: none;
		}
	}

	&__title {
		@include headers-h5();
	}

	&__buttons {
		text-align: right;
	}
}

#geoBlockingModal {
	&.modal {
		&--signup-disabled {
			backdrop-filter: blur(200px);

			.modal {
				&__wrapper {
					display: block;
					text-align: right;
					position: relative;
				}

				&__content {
					text-align: left;
				}

				&__buttons {
					display: none;
				}

				&__close-icon {
					display: inline;
					position: absolute;
					top: 16px;
					right: 24px;

					svg {
						width: 10px;
						opacity: 0.4;
					}
				}
			}
		}
	}
}
